@use "sass:string";
@use "mixins/border-radius" as *;
@use "mixins/box-shadow" as *;
@use "mixins/gradients" as *;
@use "mixins/ltr-rtl" as *;
@use "mixins/transition" as *;
@use "vendor/rfs" as *;
@use "variables" as *;

.range-slider {
  // scss-docs-start range-slider-css-vars
  --#{$prefix}range-slider-track-width: #{$range-slider-track-width};
  --#{$prefix}range-slider-track-height: #{$range-slider-track-height};
  --#{$prefix}range-slider-track-cursor: #{$range-slider-track-cursor};
  --#{$prefix}range-slider-track-bg: #{$range-slider-track-bg};
  --#{$prefix}range-slider-track-border-radius: #{$range-slider-track-border-radius};
  --#{$prefix}range-slider-track-box-shadow: #{$range-slider-track-box-shadow};
  --#{$prefix}range-slider-track-in-range-bg: #{$range-slider-track-in-range-bg};
  --#{$prefix}range-slider-disabled-track-in-range-bg: #{$range-slider-disabled-track-in-range-bg};

  --#{$prefix}range-slider-label-padding-y: #{$range-slider-label-padding-y};
  --#{$prefix}range-slider-label-padding-x: #{$range-slider-label-padding-x};
  --#{$prefix}range-slider-label-font-size: #{$range-slider-label-font-size};
  --#{$prefix}range-slider-label-color: #{$range-slider-label-color};

  --#{$prefix}range-slider-thumb-width: #{$range-slider-thumb-width};
  --#{$prefix}range-slider-thumb-height: #{$range-slider-thumb-height};
  --#{$prefix}range-slider-thumb-bg: #{$range-slider-thumb-bg};
  --#{$prefix}range-slider-thumb-border: #{$range-slider-thumb-border};
  --#{$prefix}range-slider-thumb-border-radius: #{$range-slider-thumb-border-radius};
  --#{$prefix}range-slider-thumb-box-shadow: #{$range-slider-thumb-box-shadow};
  --#{$prefix}range-slider-thumb-focus-box-shadow: #{$range-slider-thumb-focus-box-shadow};
  --#{$prefix}range-slider-thumb-active-bg: #{$range-slider-thumb-active-bg};
  --#{$prefix}range-slider-thumb-disabled-bg: #{$range-slider-thumb-disabled-bg};
  --#{$prefix}range-slider-thumb-transition: #{$range-slider-thumb-transition};

  --#{$prefix}range-slider-tooltip-zindex: #{$zindex-tooltip};
  --#{$prefix}range-slider-tooltip-padding-y: #{$range-slider-tooltip-padding-y};
  --#{$prefix}range-slider-tooltip-padding-x: #{$range-slider-tooltip-padding-x};
  --#{$prefix}range-slider-tooltip-margin-end: #{$range-slider-tooltip-margin-end};
  --#{$prefix}range-slider-tooltip-margin-bottom: #{$range-slider-tooltip-margin-bottom};
  --#{$prefix}range-slider-tooltip-font-size: #{$range-slider-tooltip-font-size};
  --#{$prefix}range-slider-tooltip-color: #{$range-slider-tooltip-color};
  --#{$prefix}range-slider-tooltip-bg: #{$range-slider-tooltip-bg};
  --#{$prefix}range-slider-tooltip-border-radius: #{$range-slider-tooltip-border-radius};
  --#{$prefix}range-slider-tooltip-box-shadow: #{$range-slider-tooltip-box-shadow};
  --#{$prefix}range-slider-tooltip-transition: #{$range-slider-tooltip-transition};

  --#{$prefix}range-slider-tooltip-arrow-width: #{$range-slider-tooltip-arrow-width};
  --#{$prefix}range-slider-tooltip-arrow-height: #{$range-slider-tooltip-arrow-height};
  // scss-docs-end range-slider-css-vars

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.disabled {
    --#{$prefix}range-slider-track-in-range-bg: var(--#{$prefix}range-slider-disabled-track-in-range-bg);

    .range-slider-track {
      cursor: initial;
    }
  }
}

.range-slider-inputs-container {
  position: relative;
  display: flex;
  align-items: center;
  height: string.unquote("max(var(--#{$prefix}range-slider-thumb-height), var(--#{$prefix}range-slider-track-height))");
}

.range-slider-track {
  width: var(--#{$prefix}range-slider-track-width);
  height: var(--#{$prefix}range-slider-track-height);
  cursor: var(--#{$prefix}range-slider-track-cursor);
  background-color: var(--#{$prefix}range-slider-track-bg);
  border-color: transparent;
  @include border-radius(var(--#{$prefix}range-slider-track-border-radius));
  @include box-shadow(var(--#{$prefix}range-slider-track-box-shadow));
}

.range-slider-input {
  position: absolute;
  z-index: 1;
  width: 100%;
  appearance: none;
  pointer-events: none;
  background-color: transparent;

  &:hover + .range-slider-tooltip,
  &:focus + .range-slider-tooltip {
    visibility: visible;
    opacity: 1;
  }

  &:focus {
    outline: 0;

    // Pseudo-elements must be split across multiple rulesets to have an effect.
    // No box-shadow() mixin for focus accessibility.
    &::-webkit-slider-thumb { box-shadow: var(--#{$prefix}range-slider-thumb-focus-box-shadow); }
    &::-moz-range-thumb     { box-shadow: var(--#{$prefix}range-slider-thumb-focus-box-shadow); }
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-webkit-slider-thumb {
    width: var(--#{$prefix}range-slider-thumb-width);
    height: var(--#{$prefix}range-slider-thumb-height);
    appearance: none;
    pointer-events: all;
    cursor: pointer;
    @include gradient-bg(var(--#{$prefix}range-slider-thumb-bg));
    border: var(--#{$prefix}range-slider-thumb-border);
    @include border-radius(var(--#{$prefix}range-slider-thumb-border-radius));
    @include box-shadow(var(--#{$prefix}range-slider-thumb-box-shadow));
    @include transition(var(--#{$prefix}range-slider-thumb-transition));

    &:active {
      @include gradient-bg(var(--#{$prefix}range-slider-thumb-active-bg));
    }
  }

  &::-moz-range-thumb {
    width: var(--#{$prefix}range-slider-thumb-width);
    height: var(--#{$prefix}range-slider-thumb-height);
    appearance: none;
    pointer-events: all;
    cursor: pointer;
    @include gradient-bg(var(--#{$prefix}range-slider-thumb-bg));
    border: $form-range-thumb-border;
    border: var(--#{$prefix}range-slider-thumb-border);
    @include border-radius(var(--#{$prefix}range-slider-thumb-border-radius));
    @include box-shadow(var(--#{$prefix}range-slider-thumb-box-shadow));
    @include transition(var(--#{$prefix}range-slider-thumb-transition));

    &:active {
      @include gradient-bg(var(--#{$prefix}range-slider-thumb-active-bg));
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 1;

    &::-webkit-slider-thumb {
      pointer-events: none;
      background-color: var(--#{$prefix}range-slider-thumb-disabled-bg);
      opacity: 1;
    }

    &::-moz-range-thumb {
      pointer-events: none;
      background-color: var(--#{$prefix}range-slider-thumb-disabled-bg);
      opacity: 1;
    }
  }
}

.range-slider-labels-container {
  position: relative;
}

.range-slider-label {
  position: absolute;
  @include font-size(var(--#{$prefix}range-slider-label-font-size));
  color: var(--#{$prefix}range-slider-label-color);
  @include ltr-rtl("transform", translateX(-50%), null, translateX(50%));

  &.clickable {
    cursor: pointer;
  }
}

.range-slider-tooltip {
  position: absolute;
  z-index: var(--#{$prefix}range-slider-tooltip-zindex);
  display: flex;
  flex-direction: column;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  @include ltr-rtl("transform", translateX(-50%), null, translateX(50%));
  @include box-shadow(var(--#{$prefix}range-slider-tooltip-box-shadow));
  @include transition(var(--#{$prefix}range-slider-tooltip-transition));
}

.range-slider-tooltip-inner {
  padding: var(--#{$prefix}range-slider-tooltip-padding-y) var(--#{$prefix}range-slider-tooltip-padding-x);
  @include font-size(var(--#{$prefix}range-slider-tooltip-font-size));
  color: var(--#{$prefix}range-slider-tooltip-color);
  background-color: var(--#{$prefix}range-slider-tooltip-bg);
  @include border-radius(var(--#{$prefix}range-slider-tooltip-border-radius));
}

.range-slider-tooltip-arrow {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
}

.range-slider:not(.range-slider-vertical) {

  .range-slider-inputs-container {
    width: 100%;
  }

  .range-slider-labels-container {
    width: calc(var(--#{$prefix}range-slider-track-width) - var(--#{$prefix}range-slider-thumb-width)); // stylelint-disable-line function-disallowed-list
  }

  .range-slider-tooltip {
    bottom: calc(var(--#{$prefix}range-slider-tooltip-margin-bottom) + var(--#{$prefix}range-slider-thumb-height)); // stylelint-disable-line function-disallowed-list
  }

  .range-slider-tooltip-arrow {
    width: var(--#{$prefix}range-slider-tooltip-arrow-width);
    height: var(--#{$prefix}range-slider-tooltip-arrow-height);

    &::before {
      border-width: var(--#{$prefix}range-slider-tooltip-arrow-height) calc(var(--#{$prefix}range-slider-tooltip-arrow-width) * .5) 0; // stylelint-disable-line function-disallowed-list
      border-top-color: var(--#{$prefix}range-slider-tooltip-bg);
    }
  }
}

.range-slider-vertical {
  // scss-docs-start range-slider-vertical-css-vars
  --#{$prefix}range-slider-vertical-track-width: #{$range-slider-vertical-track-width};
  --#{$prefix}range-slider-vertical-track-height: #{$range-slider-vertical-track-height};
  // scss-docs-end range-slider-vertical-css-vars

  flex-direction: row;
  height: var(--#{$prefix}range-slider-vertical-track-height);

  .range-slider-inputs-container {
    justify-content: center;
    width: string.unquote("max(var(--#{$prefix}range-slider-thumb-width), var(--#{$prefix}range-slider-vertical-track-width))");
    height: 100%;
  }

  .range-slider-track {
    width: var(--#{$prefix}range-slider-vertical-track-width);
    height: 100%;
  }

  .range-slider-input {
    height: 100%;
    writing-mode: vertical-lr;
    direction: rtl;
  }

  .range-slider-tooltip {
    inset-inline-end: calc(var(--#{$prefix}range-slider-tooltip-margin-end) + var(--#{$prefix}range-slider-thumb-width)); // stylelint-disable-line function-disallowed-list
    flex-direction: row;
    transform: translateY(50%);
  }

  .range-slider-tooltip-arrow {
    width: var(--#{$prefix}range-slider-tooltip-arrow-height);
    height: var(--#{$prefix}range-slider-tooltip-arrow-width);

    &::before {
      border-inline-start-color: var(--#{$prefix}range-slider-tooltip-bg);
      border-inline-width: calc(var(--#{$prefix}range-slider-tooltip-arrow-width) * .5) 0; // stylelint-disable-line function-disallowed-list
      border-top-width: calc(var(--#{$prefix}range-slider-tooltip-arrow-width) * .5); // stylelint-disable-line function-disallowed-list
      border-bottom-width: calc(var(--#{$prefix}range-slider-tooltip-arrow-width) * .5); // stylelint-disable-line function-disallowed-list
    }
  }

  .range-slider-labels-container {
    flex-shrink: 0;
    height: calc(var(--#{$prefix}range-slider-vertical-track-height) - var(--#{$prefix}range-slider-thumb-height)); // stylelint-disable-line function-disallowed-list
  }

  .range-slider-label {
    transform: translateY(50%);
  }
}
