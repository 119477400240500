@use "functions/escape-svg" as *;
@use "mixins/border-radius" as *;
@use "mixins/box-shadow" as *;
@use "mixins/breakpoints" as *;
@use "mixins/elevation" as *;
@use "mixins/ltr-rtl" as *;
@use "mixins/transition" as *;
@use "vendor/rfs" as *;
@use "variables" as *;

.date-picker,
*:not(.date-picker) > .date-picker-dropdown {
  // scss-docs-start date-picker-css-vars
  --#{$prefix}date-picker-zindex: #{$date-picker-zindex};
  --#{$prefix}date-picker-font-family: #{$date-picker-font-family};
  --#{$prefix}date-picker-font-size: #{$date-picker-font-size};
  --#{$prefix}date-picker-font-weight: #{$date-picker-font-weight};
  --#{$prefix}date-picker-line-height: #{$date-picker-line-height};
  --#{$prefix}date-picker-color: #{$date-picker-color};
  --#{$prefix}date-picker-bg: #{$date-picker-bg};
  --#{$prefix}date-picker-box-shadow: #{$date-picker-box-shadow};
  --#{$prefix}date-picker-border-width: #{$date-picker-border-width};
  --#{$prefix}date-picker-border-color: #{$date-picker-border-color};
  --#{$prefix}date-picker-border-radius: #{$date-picker-border-radius};
  --#{$prefix}date-picker-disabled-color: #{$date-picker-disabled-color};
  --#{$prefix}date-picker-disabled-bg: #{$date-picker-disabled-bg};
  --#{$prefix}date-picker-disabled-border-color: #{$date-picker-disabled-border-color};
  --#{$prefix}date-picker-focus-color: #{$date-picker-focus-color};
  --#{$prefix}date-picker-focus-bg: #{$date-picker-focus-bg};
  --#{$prefix}date-picker-focus-border-color: #{$date-picker-focus-border-color};
  --#{$prefix}date-picker-focus-box-shadow: #{$date-picker-focus-box-shadow};
  --#{$prefix}date-picker-placeholder-color: #{$date-picker-placeholder-color};
  --#{$prefix}date-picker-padding-y: #{$date-picker-padding-y};
  --#{$prefix}date-picker-padding-x: #{$date-picker-padding-x};
  --#{$prefix}date-picker-gap: #{$date-picker-gap};
  --#{$prefix}date-picker-indicator-width: #{$date-picker-indicator-width};
  --#{$prefix}date-picker-indicator-icon: #{escape-svg($date-picker-indicator-icon)};
  --#{$prefix}date-picker-indicator-icon-color: #{$date-picker-indicator-icon-color};
  --#{$prefix}date-picker-indicator-icon-size: #{$date-picker-indicator-icon-size};
  --#{$prefix}date-picker-cleaner-width: #{$date-picker-cleaner-width};
  --#{$prefix}date-picker-cleaner-icon: #{escape-svg($date-picker-cleaner-icon)};
  --#{$prefix}date-picker-cleaner-icon-color: #{$date-picker-cleaner-icon-color};
  --#{$prefix}date-picker-cleaner-icon-hover-color: #{$date-picker-cleaner-icon-hover-color};
  --#{$prefix}date-picker-cleaner-icon-size: #{$date-picker-cleaner-icon-size};
  --#{$prefix}date-picker-separator-width: #{$date-picker-separator-width};
  --#{$prefix}date-picker-separator-icon: #{escape-svg($date-picker-separator-icon)};
  --#{$prefix}date-picker-separator-icon-rtl: #{escape-svg($date-picker-separator-icon-rtl)};
  --#{$prefix}date-picker-separator-icon-size: #{$date-picker-separator-icon-size};
  --#{$prefix}date-picker-dropdown-bg: #{$date-picker-dropdown-bg};
  --#{$prefix}date-picker-dropdown-border-width: #{$date-picker-dropdown-border-width};
  --#{$prefix}date-picker-dropdown-border-color: #{$date-picker-dropdown-border-color};
  --#{$prefix}date-picker-dropdown-border-radius: #{$date-picker-dropdown-border-radius};
  --#{$prefix}date-picker-dropdown-box-shadow: #{$date-picker-dropdown-box-shadow};
  --#{$prefix}date-picker-ranges-width: #{$date-picker-ranges-width};
  --#{$prefix}date-picker-ranges-padding: #{$date-picker-ranges-padding};
  --#{$prefix}date-picker-ranges-border-width: #{$date-picker-ranges-border-width};
  --#{$prefix}date-picker-ranges-border-color: #{$date-picker-ranges-border-color};
  --#{$prefix}date-picker-timepicker-width: #{$date-picker-timepicker-width};
  --#{$prefix}date-picker-timepicker-border-color: #{$date-picker-timepicker-border-color};
  --#{$prefix}date-picker-timepicker-border-top: #{$date-picker-timepicker-border-width} solid var(--#{$prefix}date-picker-timepicker-border-color);
  --#{$prefix}date-picker-footer-padding: #{$date-picker-footer-padding};
  --#{$prefix}date-picker-footer-border-width: #{$date-picker-footer-border-width};
  --#{$prefix}date-picker-footer-border-color: #{$date-picker-footer-border-color};
  // scss-docs-end date-picker-css-vars

  &.is-invalid {
    --#{$prefix}date-picker-border-color: #{$date-picker-invalid-border-color} !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}date-picker-indicator-icon-color: #{$date-picker-indicator-invalid-icon-color} !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}date-picker-indicator-icon: #{escape-svg($date-picker-indicator-invalid-icon)} !important; // stylelint-disable-line declaration-no-important
  }

  &.is-valid {
    --#{$prefix}date-picker-border-color: #{$date-picker-valid-border-color} !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}date-picker-indicator-icon-color: #{$date-picker-indicator-valid-icon-color} !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}date-picker-indicator-icon: #{escape-svg($date-picker-indicator-valid-icon)} !important; // stylelint-disable-line declaration-no-important
  }
}

.date-picker-input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: var(--#{$prefix}date-picker-gap);
  align-items: stretch;
  width: 100%;
  padding: var(--#{$prefix}date-picker-padding-y) var(--#{$prefix}date-picker-padding-x);
  background-color: var(--#{$prefix}date-picker-bg);
  background-clip: padding-box;
  border: var(--#{$prefix}date-picker-border-width) solid var(--#{$prefix}date-picker-border-color);
  @include border-radius(var(--#{$prefix}date-picker-border-radius));

  &:hover {
    .date-picker-input:not(:placeholder-shown) {
      ~ .date-picker-indicator:not(:last-child) {
        display: none;
      }
      ~ .date-picker-cleaner {
        display: flex;
      }
    }
  }

  .date-picker & {
    @include transition($input-transition);
  }

  .date-picker.disabled & {
    --#{$prefix}date-picker-border-color: var(--#{$prefix}date-picker-disabled-border-color);
    background-color: var(--#{$prefix}date-picker-disabled-bg);
  }

  .date-picker.show &,
  &:has(*:focus) {
    background-color: var(--#{$prefix}date-picker-focus-bg);
    border-color: var(--#{$prefix}date-picker-focus-border-color);
    outline: 0;
    @if $enable-shadows {
      box-shadow: var(--#{$prefix}date-picker-box-shadow), var(--#{$prefix}date-picker-focus-box-shadow);
    } @else {
      box-shadow: var(--#{$prefix}date-picker-focus-box-shadow);
    }
  }
}

.date-picker-input {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  padding: 0;
  font-family: var(--#{$prefix}date-picker-font-family);
  @include font-size(var(--#{$prefix}date-picker-font-size));
  font-weight: var(--#{$prefix}date-picker-font-weight);
  line-height: var(--#{$prefix}date-picker-line-height);
  color: var(--#{$prefix}date-picker-color);
  appearance: none;
  background: transparent;
  border: 0;

  .date-picker.show & {
    color: var(--#{$prefix}date-picker-focus-color);
  }

  &:disabled {
    color: var(--#{$prefix}date-picker-disabled-color);
  }

  &:focus {
    z-index: 5;
    outline: 0;
  }

  &::placeholder {
    color: var(--#{$prefix}date-picker-placeholder-color);
    opacity: 1;
  }

  &.hover {
    color: var(--#{$prefix}date-picker-placeholder-color);
  }
}

.date-picker-cleaner,
.date-picker-separator,
.date-picker-indicator {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
  }
}

.date-picker-cleaner {
  display: none;
  width: var(--#{$prefix}date-picker-cleaner-width);

  &::before {
    background-color: var(--#{$prefix}date-picker-cleaner-icon-color);
    mask: var(--#{$prefix}date-picker-cleaner-icon) center / var(--#{$prefix}date-picker-cleaner-icon-size) no-repeat;
  }

  &:hover::before {
    background-color: var(--#{$prefix}date-picker-cleaner-icon-hover-color);
  }
}

.date-picker-indicator {
  width: var(--#{$prefix}date-picker-indicator-width);

  &::before {
    background-color: var(--#{$prefix}date-picker-indicator-icon-color);
    mask: var(--#{$prefix}date-picker-indicator-icon) center / var(--#{$prefix}date-picker-indicator-icon-size) no-repeat;
  }

  &:focus {
    z-index: 5;
    outline: 0;
    box-shadow: var(--#{$prefix}focus-ring-x, 0) var(--#{$prefix}focus-ring-y, 0) var(--#{$prefix}focus-ring-blur, 0) var(--#{$prefix}focus-ring-width) var(--#{$prefix}focus-ring-color);
    @include border-radius($border-radius);
  }
}

.date-picker-separator {
  width: var(--#{$prefix}date-picker-separator-width);

  &::before {
    background-color: $date-picker-separator-icon-color;
    @include ltr-rtl-value-only(
      "mask",
      var(--#{$prefix}date-picker-separator-icon) center / var(--#{$prefix}date-picker-separator-icon-size) no-repeat,
      var(--#{$prefix}date-picker-separator-icon-rtl) center / var(--#{$prefix}date-picker-separator-icon-size) no-repeat
    );
  }
}

.date-picker-dropdown {
  position: absolute;
  z-index: var(--#{$prefix}date-picker-zindex);
  display: none;
  width: min-content;
  background-color: var(--#{$prefix}date-picker-dropdown-bg);
  background-clip: padding-box;
  border: var(--#{$prefix}date-picker-dropdown-border-width) solid var(--#{$prefix}date-picker-dropdown-border-color);
  @include border-radius(var(--#{$prefix}date-picker-dropdown-border-radius));
  @include box-shadow(var(--#{$prefix}date-picker-dropdown-box-shadow));
  @include elevation(4);

  .date-picker.show &,
  &.show {
    display: block;
  }
}

.date-picker-body {
  display: grid;
  grid-template-areas:
    "ranges calendars"
    "ranges timepickers";
  grid-template-rows: auto;
  grid-template-columns: auto auto;
}

.date-picker-footer {
  display: flex;
  justify-content: flex-end;
  padding: var(--#{$prefix}date-picker-footer-padding);
  border-top: var(--#{$prefix}date-picker-footer-border-width) solid var(--#{$prefix}date-picker-footer-border-color);

  .btn + .btn {
    margin-inline-start: .5rem;
  }
}

.date-picker-ranges {
  grid-area: ranges;
  width: var(--#{$prefix}date-picker-ranges-width);
  padding: var(--#{$prefix}date-picker-ranges-padding);
  border-inline-end: var(--#{$prefix}date-picker-ranges-border-width) solid var(--#{$prefix}date-picker-ranges-border-color);
}

.date-picker-calendars {
  display: flex;
  grid-area: calendars;

  .date-picker-calendar {
    &:not(:last-child) {
      .calendar-navigation-next {
        visibility: hidden;
      }
    }
    &:not(:first-child) {
      .calendar-navigation-prev {
        visibility: hidden;
      }
    }
  }
}

.date-picker-timepickers {
  display: flex;
  flex-wrap: wrap;
  grid-area: timepickers;
  justify-content: space-between;
  border-top: var(--#{$prefix}date-picker-timepicker-border-top);

  .time-picker {
    width: var(--#{$prefix}date-picker-timepicker-width);
  }

  @include media-breakpoint-down(md) {
    display: block;

    .time-picker {
      width: 100%;

      + .time-picker {
        border-top: var(--#{$prefix}date-picker-timepicker-border-top);
      }
    }
  }
}

.date-picker-sm {
  --#{$prefix}date-picker-font-size: #{$date-picker-font-size-sm};
  --#{$prefix}date-picker-border-radius: #{$date-picker-border-radius-sm};
  --#{$prefix}date-picker-padding-y: #{$date-picker-padding-y-sm};
  --#{$prefix}date-picker-padding-x: #{$date-picker-padding-x-sm};
  --#{$prefix}date-picker-gap: #{$date-picker-gap-sm};
  --#{$prefix}date-picker-cleaner-width: #{$date-picker-cleaner-width-sm};
  --#{$prefix}date-picker-cleaner-icon-size: #{$date-picker-cleaner-icon-size-sm};
  --#{$prefix}date-picker-indicator-width: #{$date-picker-indicator-width-sm};
  --#{$prefix}date-picker-indicator-icon-size: #{$date-picker-indicator-icon-size-sm};
  --#{$prefix}date-picker-separator-width: #{$date-picker-separator-width-sm};
  --#{$prefix}date-picker-separator-icon-size: #{$date-picker-separator-icon-size-sm};
}

.date-picker-lg {
  --#{$prefix}date-picker-font-size: #{$date-picker-font-size-lg};
  --#{$prefix}date-picker-border-radius: #{$date-picker-border-radius-lg};
  --#{$prefix}date-picker-padding-y: #{$date-picker-padding-y-lg};
  --#{$prefix}date-picker-padding-x: #{$date-picker-padding-x-lg};
  --#{$prefix}date-picker-gap: #{$date-picker-gap-lg};
  --#{$prefix}date-picker-cleaner-width: #{$date-picker-cleaner-width-lg};
  --#{$prefix}date-picker-cleaner-icon-size: #{$date-picker-cleaner-icon-size-lg};
  --#{$prefix}date-picker-indicator-width: #{$date-picker-indicator-width-lg};
  --#{$prefix}date-picker-indicator-icon-size: #{$date-picker-indicator-icon-size-lg};
  --#{$prefix}date-picker-separator-width: #{$date-picker-separator-width-lg};
  --#{$prefix}date-picker-separator-icon-size: #{$date-picker-separator-icon-size-lg};
}
